import React, {useState, useContext} from "react";
import {Link, navigate} from "gatsby";
import {CartContext} from '../cart';

import {CompactButton} from "../components/button";
import {useIsMobile} from "../utils";

const Cart = () => {
    const {isMobile} = useIsMobile();
    const {checkout, removeItem, checkoutId} = useContext(CartContext);
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const items = checkout?.items || [];
    const checkoutCSV = ({ subtype }) => {
      if (!phone || !name || !email) {
        return;
      }
      // fetch('https://coffee-ecpay-logistics.ggininderr.workers.dev/cvs-select').then((res) => {
      //   return res.text();
      // }).then((html) => {
      //   document.getElementsByTagName('html')[0].innerHTML = html;
      // });


           var form = document.createElement('form');
           form.style.visibility = 'hidden';
           form.method = 'POST';
           form.action = 'https://logistics.ecpay.com.tw/Express/map';
           const body = new FormData();
           const payload = {
             'MerchantID': '3285192',
             'LogisticsType': 'CVS',
             'LogisticsSubType': subtype,
             'IsCollection': 'N',
             'ServerReplyURL': `https://coffee-ecpay-logistics.ggininderr.workers.dev/cvs-callback?phone=${phone}&name=${name}&cid=${checkoutId}&email=${email}`
           }
           for (const key in payload) {
             var input = document.createElement('input');
             input.name = key;
             input.value = payload[key];
             form.appendChild(input)
           }
      document.body.appendChild(form);
      form.submit();
    }
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    maxWidth: 800,
                    margin: '48px auto',
                    justifyContent: 'center',
                    padding: 24,
                    alignItems: 'start',
                    ...(isMobile ? {
                        flexDirection: 'column'
                    } : {}),
                }}
            >
                <div style={{
                    display: "flex",
                    flex: 3,
                    flexDirection: "column",
                }}>
                    <div>
                      請輸入取貨人姓名
                    </div>
                    <input 
                      style={{ background: 'transparent', border: '1px solid', maxWidth: 400 }}
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <div style={{ marginTop: 24 }}>
                      請輸入取貨人手機
                    </div>
                    <input 
                      style={{ background: 'transparent', border: '1px solid', maxWidth: 400 }}
                      type="text"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <div style={{ marginTop: 24 }}>
                      請輸入 Email
                    </div>
                    <input 
                      style={{ background: 'transparent', border: '1px solid', maxWidth: 400 }}
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div
                      style={{ display: 'flex', marginTop: 24, justifyContent: 'end', gap: 24 }}
                    >
                      <CompactButton
                        onClick={() => {
                          checkoutCSV({ subtype: 'UNIMART' });
                        }}
                      >
                          7-11 超商取貨
                      </CompactButton>
                      <CompactButton
                        onClick={() => {
                          checkoutCSV({ subtype: 'FAMI' });
                        }}
                      >
                          全家超商取貨
                      </CompactButton>
                    </div>
                    <div style={{ marginTop: 24 }} />
                    {
                        (items.length < 1  || !checkout )&& (
                            <div>
                                <h5>
                                    購物車中沒有商品
                                </h5>
                                <Link to="/shop">
                                    前往商店
                                </Link>
                            </div>
                        )
                    }
                    {
                        items.map((e) => <div key={e.id} style={{
                            margin: '1rem',
                            marginLeft: 0,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                        >
                            <div
                                onClick={() => {
                                    navigate(e.url);
                                }}
                            >
                                <img
                                    style={{
                                        width: 100,
                                        height: 100,
                                        cursor: 'pointer',
                                    }}
                                    src={e.imageUrl}/>
                            </div>
                            <div
                                style={{
                                    width: 24,
                                }}
                            >

                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <div
                                    onClick={() => {
                                        navigate(e.url);
                                    }}
                                    style={{
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                }}>

                                    {e.title}
                                </div>
                                {
                                  e.engName && <div>{e.engName}</div>
                                }
                                {
                                  e.customAttributes.length > 0 && <div>
                                    {e.customAttributes.map((e) => `${e.key}: ${e.value}`).join(',')}
                                  </div>
                                }
                                <div>
                                    {e.spec} x {e.count}
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div>

                                        {`\$${e.price}`}
                                    </div>
                                </div>
                            </div>
                        </div>)
                    }
                </div>
            </div>
        </div>
    );
};

export default Cart;
