import React, {useState} from "react";
import LoadingIcon from "./loading";

export const CompactButton = function ({onClick, children}) {
    const [loading, setLoading] = useState(false);

    return <div onClick={async () => {
        setLoading(true);
        await onClick();
        setLoading(false);
    }} style={{
        color: "#fff",
        backgroundColor:
            loading ? "#fff" : "#000",
        display: 'flex',
        cursor: "pointer",
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        paddingRight: "2rem",
        paddingLeft: "2rem",
        border:
            loading ? "none" : "1px solid #000",
        alignItems: "center",
        justifyContent: "center",
    }}>
        {
            loading ? <LoadingIcon/> :
                children}
    </div>;
}
