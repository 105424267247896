import React, { useState } from "react";
const LoadingIcon = () => <div
    className="loading-icon"
>
    <img
        style={{
            width: 20,
        }}
        src="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
    />
</div>

export  default  LoadingIcon;